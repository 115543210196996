import React from 'react'
import BaseApp from 'next/app'
import { appWithTranslation } from '../../i18n'
import { getClient } from '../utils/sanity'
import { ApolloProvider } from '@apollo/client'
import { initializeApollo } from '../../lib/apolloClient'

import '../styles/index.scss'

const siteConfigQuery = `
  *[_id == "global-config"] {
    ...,
    logo {asset->{extension, url}},
    mainNavigation[] -> {
      ...,
      "title": page->title
    },
    footerNavigation[] -> {
      ...,
      "title": page->title
    }
  }[0]
  `

class App extends BaseApp {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    // Add site config from sanity
    return getClient()
      .fetch(siteConfigQuery)
      .then(config => {
        if (!config) {
          return { pageProps }
        }
        if (config && pageProps) {
          pageProps.config = config
        }

        return { pageProps }
      })
  }

  render() {
    const { Component, pageProps } = this.props
    const apolloClient = initializeApollo()
    return (
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    )
  }
}

export default appWithTranslation(App)
