import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { isProduction } from '../src/utils/envUtils'

let apolloClient
const isProductionEnv = isProduction()

const httpLink = createHttpLink({
  uri: isProductionEnv
    ? process.env.NEXT_PUBLIC_PROD_SHOPIFY_GRAPHQL_URL
    : process.env.NEXT_PUBLIC_DEV_SHOPIFY_GRAPHQL_URL
})

const authLink = setContext(() => ({
  headers: {
    'X-Shopify-Storefront-Access-Token': isProductionEnv
      ? process.env.NEXT_PUBLIC_PROD_SHOPIFY_ACCESS_TOKEN
      : process.env.NEXT_PUBLIC_DEV_SHOPIFY_ACCESS_TOKEN
  }
}))

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })
}

export function initializeApollo() {
  const _apolloClient = apolloClient ?? createApolloClient()

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}
